import {
  CRA_REPORTS_LIST_BEGIN,
  CRA_REPORTS_LIST_SUCCESS,
  CRA_REPORTS_LIST_FAILURE,
  CRA_REPORTS_LIST_DISMISS_ERROR,
  SIDE_MENU_TOGGLE,
} from './constants';

import { REACT_APP_API_KEY } from '../../../aws-exports';

import { API } from 'aws-amplify';
import { updateAuthToken, checkUser } from '../../home/redux/checkUser';

export const toggleSideMenu = () => {
  return dispatch => {
    dispatch({
      type: SIDE_MENU_TOGGLE,
    });
  };
};

export const queryCRAReports = ({ dateRange }, screen = null) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const { jwt, email } = await getState().home;
          if (!email) {
            reject('No autenticated user');
            return;
          }
          const [startDate, endDate] = dateRange;
          if (startDate >= endDate) {
            reject('Filter - Start Date is greater than End Date');
            return;
          }
          await dispatch({
            type: 'CLEAR_CRA_REPORT_DETAILS',
          });
          await dispatch({
            type: CRA_REPORTS_LIST_BEGIN,
          });
          const payload = {};
          if (dateRange && dateRange.length >= 2) {
            payload.dateRange = JSON.stringify(dateRange);
          }
          const apiName = 'crareportapi';
          const path = '/cra/dashboarduser/getallreports?email=' + email;
          const options = {
            headers: {
              Authorization: jwt,
              'x-api-key': REACT_APP_API_KEY,
            }, // OPTIONAL
            // response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {
              // OPTIONAL
              ...payload,
            },
          };
          const response = await API.get(apiName, path, options);
          await dispatch({
            type: CRA_REPORTS_LIST_SUCCESS,
            data: response,
            screen: screen,
          });
          resolve(response);
        } catch (error) {
          if (error?.response?.status === 401) {
            await dispatch(updateAuthToken());
            await dispatch(queryCRAReports({ dateRange }, screen));
          } else {
            await dispatch({
              type: CRA_REPORTS_LIST_FAILURE,
              data: { error: error },
              screen: screen,
            });
            reject(error);
          }
        }
      })();
    });
  };
};

export const dismissQueryReportError = () => {
  return {
    type: CRA_REPORTS_LIST_DISMISS_ERROR,
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SIDE_MENU_TOGGLE:
      return {
        ...state,
        sideMenu: !state.sideMenu,
      };
    case CRA_REPORTS_LIST_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        loading: true,
        reportlist: null,
        reportDetails: null,
        reportActionResult: null,
        queryCRAReportsPending: true,
        queryCRAReportsError: null,
      };

    case CRA_REPORTS_LIST_SUCCESS:
      // The request is success
      return {
        ...state,
        reportlist: action.data,
        loading: false,
        queryCRAReportsPending: false,
        queryCRAReportsError: null,
        screen: action.screen,
      };

    case CRA_REPORTS_LIST_FAILURE:
      // The request is failed
      return {
        ...state,
        loading: false,
        queryCRAReportsPending: false,
        queryCRAReportsError: action.data.error,
      };

    case CRA_REPORTS_LIST_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        loading: false,
        queryCRAReportsError: null,
      };

    default:
      return state;
  }
};
