// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export const REACT_APP_API_KEY =
  process.env.NODE_ENV === 'development'
    ? 'iraJZHcH2a3GAYJ47uiqn79mUnUd65vw6vXJ9uBO'
    : process.env.REACT_APP_STAGE === 'development'
    ? 'iraJZHcH2a3GAYJ47uiqn79mUnUd65vw6vXJ9uBO'
    : 'MvzcHV3kSP8zSX7UqlAA12SU29X9wPAy9gYtX7H2';
//export const REACT_APP_API_KEY = 'iraJZHcH2a3GAYJ47uiqn79mUnUd65vw6vXJ9uBO'
const redirectURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:6075/'
    : process.env.REACT_APP_STAGE === 'development'
    ? 'https://cra-dashboard-np.dish.com/'
    : 'https://cra-dashboard.dish.com/';

const awsmobile_nonprod = {
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:91fdea15-ceca-4a0c-9f80-d809dfb3a8c9',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_71D5Np1eQ',
  aws_user_pools_web_client_id: '5itccbdi1k5kmksail7bea0qt0',
  Auth: {
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: 'itma-users.auth.us-west-2.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'openid',
        'aws.cognito.signin.user.admin',
        'profile',
        'https://itma-customscope.com/cra.admin',
      ],
      redirectSignIn: redirectURL,
      redirectSignOut: redirectURL,
      responseType: 'code',
    },
  },
  Storage: {
    AWSS3: {
      bucket: 'itma-cra-report-items',
      region: 'us-west-2',
      identityPoolId: 'us-west-2:91fdea15-ceca-4a0c-9f80-d809dfb3a8c9',
    },
  },
  API: {
    // graphql_endpoint: 'https://eucc63dpivgjrb3zhbsieekdie.appsync-api.us-west-2.amazonaws.com/graphql',
    // graphql_endpoint_iam_region: 'us-west-2',
    endpoints: [
      {
        name: 'crareportapi',
        endpoint: 'https://gwbgmtumt2.execute-api.us-west-2.amazonaws.com/v1',
      },
    ],
  },
};

const awsmobile_prod = {
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:50dc910a-972f-451b-ae9e-024a24fd3341',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_AA8LPJiPj',
  aws_user_pools_web_client_id: '1vk0a7gt7n8csojt60hi6dft9f',
  Auth: {
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: 'itma-ota-users.auth.us-west-2.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'openid',
        'aws.cognito.signin.user.admin',
        'profile',
        'https://itma-customscope.com/cra.admin',
      ],
      redirectSignIn: redirectURL,
      redirectSignOut: redirectURL,
      responseType: 'code',
    },
  },
  Storage: {
    AWSS3: {
      bucket: 'itma-cra-report-items-p',
      region: 'us-west-2',
      identityPoolId: 'us-west-2:50dc910a-972f-451b-ae9e-024a24fd3341',
    },
  },
  API: {
    // graphql_endpoint: 'https://eucc63dpivgjrb3zhbsieekdie.appsync-api.us-west-2.amazonaws.com/graphql',
    // graphql_endpoint_iam_region: 'us-west-2',
    endpoints: [
      {
        name: 'crareportapi',
        endpoint: 'https://p0i2tl7or1.execute-api.us-west-2.amazonaws.com/v1',
      },
    ],
  },
};

const awsmobile = process.env.REACT_APP_STAGE === 'production' ? awsmobile_prod : awsmobile_nonprod;

export default awsmobile;
